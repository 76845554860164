.miscellaneous-view
{
	.miscellaneous-functionalities
	{
		.item .icon:first-child
		{
			color: $miscellaneous-icon-color;
		}

		.item .title
		{
			font-weight:500;
		}

		.item .description
		{
			color: lighten($content-color, 30%);
		}
	}
}
