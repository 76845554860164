ion-side-menu
{
	ion-content
	{
		background-color: darken($main-menu-bg, 12);
	}

	.item-divider {
		background-color: darken($main-menu-bg, 16);
		color: darken($main-menu-color, 20);
	}

	ion-item.item
	{
		border-right:none;
		border-left:none;
		border-color: darken($main-menu-bg, 5);

		.item-content
		{
			background-color: darken($main-menu-bg, 12);

			.icon:before
			{
				background-color: $main-menu-bg;
				color: $main-menu-color;
				border-radius: 50%;
				font-size: 22px;
				width: 32px;
				height: 32px;
				line-height: 32px;
			}
		}

		.item-content.activated
		{
			background-color: lighten($main-menu-bg, 10);
		}

		.menu-text
		{
			color: darken($main-menu-color, 20);
			font-weight:500;
		}
	}

	ion-item.item:first-child
	{
		border-top:none;
	}

	ion-item.item:last-child
	{
		border-bottom:none;
	}

	.heading-item
	{
		margin-top: 10px; //THis is so that the top bar on iphone jack with it
		background-color: darken($main-menu-bg, 10);

		.market-info-container {
			width: 100%;
			margin-left: -75px;
			margin-top: 30px;

			table {
				width: 270px;
			}

			td.stat {
				position: relative;
				width: 33%;
				text-align: center;
			}

			td.time {
				position: relative;
				width: 100%;
				padding-top: 10px;
				text-align: center;
			}

			.price {

			}

			.metal {
				font-size: 70px;
				font-weight: bold;
				opacity: 0.2;
			}

			.price {
				color: white;
				text-align: right;
				margin-right: 5px;
				font-weight: bold;
				-webkit-transition: all 0.5s ease;
		    -moz-transition: all 0.5s ease;
		    -o-transition: all 0.5s ease;
		    transition: all 0.5s ease;
			}

			.highlight {
			  color: lightyellow;
				opacity: 0.5;
			}

			.timestamp {
				color: white;
				opacity: 0.4;
				-webkit-transition: all 0.5s ease;
		    -moz-transition: all 0.5s ease;
		    -o-transition: all 0.5s ease;
		    transition: all 0.5s ease;
			}

		}


		.item-content
		{
			height: 120px;
			background-color: darken($main-menu-bg, 10);
			padding-bottom: 0px;
		}

		.user-image-container
		{
			width:90px;
			position: absolute;
		  top: 16px;
		  left: 12px;

			.user-image
			{
				border-radius: 50%;
				padding: 8px;
				border: 1px solid darken($main-menu-color, 40);
				width: 100%;
			}
		}

		.greeting
		{
			color: $main-menu-color;
			font-weight:500;
		}

		.message
		{
			color: darken($main-menu-color, 40);
		}
	}
}
