// General reset styles
*
{
	color: $content-color;
}

.bar.app-top-bar
{
	background-color: $top-bar-bg;

	.title
	{
		color: $top-bar-color !important;
		*
		{
			color: $top-bar-color !important;
		}
	}

	.button
	{
		color: $top-bar-color !important;
		*
		{
			color: $top-bar-color !important;
		}
	}
}

// Preload images aux styles (ratios)
// Auto generate ratios for the whole app (see: http://stackoverflow.com/a/18120181/1116959)

@each $ratio in $pre-img-ratios {
  .pre-img._#{nth($ratio, 1)}_#{nth($ratio, 2)}
  {
    // Conserve aspect ratio (see: http://stackoverflow.com/a/10441480/1116959)
    width: 100%;
		@include calc(padding-bottom, "#{( nth($ratio, 2) / nth($ratio, 1)) * 100}%");
  }
}

.pre-img
{
  position: relative;
  background-color: $pre-img-bg;

	// If the image is rounded
	&.rounded-image
	{
		border-radius: 50%;
	}

  &.finish-loading
  {
    background-color: transparent;
    border: none;

		img
	  {
			visibility: visible;
			opacity: 1;
	  }
  }

  img
  {
    position: absolute;
		left: 0px;
		top: 0px;
		visibility: hidden;
		opacity: 0;
		@include transition(visibility 0s linear, opacity 0.5s linear);
  }
}

// spinner-on-load
@include keyframes(spin) {
  from {
    @include transform(rotate(0deg));
  }
  to {
    @include transform(rotate(359deg));
  }
}
.spinner-on-load
{
	position: absolute;
	font-size: $spinner-size;
	width: $spinner-size;
	height: $spinner-size;
	line-height: $spinner-size;
  color: $spinner-color;
	@include animation(spin 2s infinite linear);
	@include calc(top, "50% - #{($spinner-size/2)}");
	@include calc(left, "50% - #{($spinner-size/2)}");
}
ion-spinner.spinner-on-load
{
	@include animation(none);
  stroke: $spinner-color;
	width: $spinner-svg-size;
  height: $spinner-svg-size;
	line-height: inherit;
	@include calc(top, "50% - #{($spinner-svg-size/2)}");
	@include calc(left, "50% - #{($spinner-svg-size/2)}");
}

// Multimedia background
.multi-bg-outer
{
  position: relative;
  background-color: rgba(darken($multi-bg, 10%), 0.7);
  height: 100%;
  @include transition(all ease-in-out .3s);

  background-size: cover;
  background-repeat: no-repeat;
  z-index: -2;

  &.finish-loading
  {
    background-color: transparent;
  }

  .bg-overlay
  {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $overlay-bg;
    z-index: -1;
    opacity: 0.6;
  }

  .multi-bg
  {
    display: none;
  }
}

.loading-container .loading
{
	background-color: $loading-background-color;
  *
  {
    color: darken($loading-color, 5);
		font-weight: 500;
  }
}

.button-danger{
    background-color: #DD4B39 !important;
    color: white !important;
}

.button-danger.activated { background-color: #CD3723 !important; }

.circle {
	width: 15px;
	height: 15px;
	background: transparent;
	-moz-border-radius: 7px;
	-webkit-border-radius: 7px;
	border-radius: 7px;
	float:left;
	margin-right: 10px;
	margin-top: 2px;
}
