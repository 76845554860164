@each $font-size in $font-size-list {
  $i: index($font-size-list, $font-size);
  .article-size-#{$font-size}
  {
    .article-view .article-text
    {
      font-size: $font-size;
    }
  }
}

.article-view
{
  .article-heading
  {
    padding-bottom:0px;
    border-color: transparent;
  }

  .article-content
  {
    border-color: transparent;

    // Audio fix
    .wp-audio-shortcode
    {
      visibility: visible !important;
    }

    .article-text
    {
      *
      {
        font-size: inherit !important;
      }
    }

    // Fix audio tag not displaying to 100% on ios
    audio::-webkit-media-controls-panel
    {
      width: 100% !important;
    }

    img
    {
      margin: 10px 0px;
      height: auto;
      @include calc(width, "100% + #{($sides-padding*2)}");
      margin-left: - $sides-padding;
    }

    ul, ol
    {
      list-style-type: square;
      padding: 0 0 0 40px;
      margin: 14px 0px;
    }

    iframe
    {
      margin: 10px 0px;
      @include calc(width, "100% + #{($sides-padding*2)}");
      margin-left: - $sides-padding;
    }

    blockquote
    {
      font-style: italic;
      font-weight: 300;
      margin: 20px 20px;
      border: none;
      padding: 0px;

      p
      {
        font-size: 16px;
      }
    }
  }

  .article-tags
  {
    border-color: transparent;
    padding-top: 0px;
    padding-bottom: 5px;

    .article-tag
    {
      padding: 0 3px;
      min-height: 24px;
      font-size: 12px;
      line-height: 24px;
      margin-right: 5px;
      margin-bottom: 5px;
    }

    .article-tag:last-child
    {
      margin-right:0px;
    }
  }

  .article-footer
  {
    padding:0px;
    background-color: darken($article-footer-bg, 10%);

    .row,
    .col
    {
      padding: 0px;
    }

    .col.col-20
    {
      text-align: center;
    }

    .ion-plus
    {
      font-size: 24px;
    }

    .ion-plus:before
    {
      font-size: 20px !important;
      line-height: 15px !important;
    }

    .ion-minus
    {
      font-size: 18px;
    }

    .ion-minus:before
    {
      font-size: 14px !important;
      line-height: 20px !important;
      margin-left: 3px;
    }
  }

  .article-title
  {
    font-size: 24px;
    margin-bottom: 4px;
    color: $article-footer-bg;
  }

  .article-author
  {
    font-weight: 500;
  }
}
