.profile-view
{
  background-color: $profile-bg !important;

  .profile-container
  {
    margin: 0px auto;
    margin-top: 6vh;
    text-align: center;
  }

  .user-image-container
  {
    width: $image-width;

    .user-image
    {
      border-top: 1px solid $content-color;
      padding: 8px;
      background-color: $profile-bg;
      width: 100%;
      border-radius: 50%;
    }
  }

  .user-name
  {
    font-size: 20px;
  }

  .user-twitter
  {
    margin-top: 5px;
  }

  .top-content
  {
    border-bottom: 1px solid $content-color;
    position: relative;
    height: 20vh;
    margin-bottom: 25vh;
  }

  .user-background-image-outer
  {
    height: 20vh;
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
  }

  .bottom-content
  {
    padding: 0px;
    background-color: $profile-bg;
    min-height: 55vh;
    position: relative;

    .user-bio
    {
      text-align: center;
      margin: 6px 30px;
      padding: 6px 0px;
    }
  }
}
