.item-stacked-label.tall{
    height:120px;
}

.category-result-item {

  .title {
    font-weight: bold;
  }

  .col {
    padding-top: 0px;
  }

}

.catalog-view
{
  background-color: $content-bg;

  .bar-header {
    background-color: white;
  }

  .search-box {
    width: 100%;

    .k-textbox {
      background-color: white;
    }

    span.k-textbox a.k-icon {
      background-position: -32px -16px;
    }

    input[type=text] {
      font-size: 18px;
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgBAMAAACBVGfHAAAACXBIWXMAAABIAAAASABGyWs+AAAACXZwQWcAAAAgAAAAIACH+pydAAAAKlBMVEUAAAD///8AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAD1lHmjAAAADnRSTlMAAAQIDBAUICQoMDQ4QE0be7wAAAABYktHRAH/Ai3eAAAAwElEQVQoz43RsQrCMBAG4KOg6Ka4FEQQ1y6Oju4iuLi7+haimyA4+Ryirn0EQVCwWv538frHaJIuvSl8/LlcEmkHJRWgJTJKkW+ERWigqNUPopSQ9y0kwGG+BE4WdjjraoLsCx0T1o1DAzEebDdlW4Uu9oQmjgZ6WBDquFkYE2p/mIUQbCk1jXH3jy0NpqNfOHoeXo69nOsDL/+Brow4T5gwYkBkwGM0YqEoRlxgxIUiknmgkbcHGnn6EG3XFT77A6uOcvKZ/VTJAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDEwLTAyLTExVDExOjUwOjA4LTA2OjAw1hBl+wAAACV0RVh0ZGF0ZTptb2RpZnkAMjAwNi0wNS0wNVQxMzoyMjo0MC0wNTowML/k/hoAAAAASUVORK5CYII=);
      background-repeat: no-repeat;
      background-size: 25px 25px;
      background-position: 3px center;
      text-indent: 35px;
    }

    input::-webkit-input-placeholder { /* WebKit browsers */
      text-indent: 35px;
    }

    input:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
      text-indent: 35px;
    }
    input::-moz-placeholder { /* Mozilla Firefox 19+ */
      text-indent: 35px;
    }
    input:-ms-input-placeholder { /* Internet Explorer 10+ */
      text-indent: 35px;
    }

  }

}

.search-option {
  font-size: 18px;
}
