// THEME 1
// $theme-color-1: #387ef5;
// $theme-color-2: #FF4C4C;
// $theme-color-3: #3E454C;

// THEME 2
// $theme-color-1: #633CA6;
// $theme-color-2: #F4C447;
// $theme-color-3: #414DA8;

// THEME 3
$theme-color-1: #33AC6B;
$theme-color-2: #33779B;
$theme-color-3: #3E454C;
